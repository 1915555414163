<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <v-chart class="sentiment-pie" :option="sentimentPieOptions" />
          <div class="ml-3">
            <h5 class="font-weight-bold mb-0 text-success">POSITIF <span
                class="text-success font-size-sm font-weight-normal"> (+2.9%)</span></h5>
            <span class="badge badge-mark border-success mr-1"></span> <span class="text-muted">Jun 16, 10:00
              am</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <ul class="nav nav-pills justify-content-end mb-0">
            <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active"
                data-toggle="tab">Days</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
          </ul>
          <div id="tickets-status"></div>
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker
                class="form-control"
                control-container-class=""
                opens="left"
                append-to-body
                :date-range="dateRange"
                style="min-width: 180px"
              />
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <template #header>
          <b-card-title class="font-weight-bold" title-tag="h6">Mention Summary</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart :option="mentionSummaryOptions" class="chart has-fixed-height" />
        </div>
      </b-card>
      <b-row>
        <b-col md="9">
          <div class="card">
            <div class="card-body">
              <div class="cat_switch">
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-earth"></i>
                  </div>
                  <p>All</p>
                  <h3>10</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_fb">
                    <i class="icon-facebook"></i>
                  </div>
                  <p>Facebook</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_ig">
                    <i class="icon-instagram"></i>
                  </div>
                  <p>Instagram</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-twitter"></i>
                  </div>
                  <p>Twitter</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-youtube"></i>
                  </div>
                  <p>Youtube</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-newspaper2"></i>
                  </div>
                  <p>Blog</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-sphere"></i>
                  </div>
                  <p>Web</p>
                  <h3>0</h3>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group mb-0 mt-1">
                    <div class="form-check pl-0">
                      <label class="form-check-label">
                        <b-form-checkbox class="form-check-input-styled">Nonaktifkan mention dari akun sendiri</b-form-checkbox>
                      </label>
                    </div>
                    <div class="form-check pl-0">
                      <label class="form-check-label">
                        <b-form-checkbox class="form-check-input-styled">Select Mention</b-form-checkbox>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group mb-0">
                    <b-input-group>
                      <b-form-input class="border-right-0" placeholder="Search..." />
                      <template #append>
                        <b-btn class="bg-indigo-400">Search</b-btn>
                      </template>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label> Filter by</label>
                  <b-form-select v-model="selFilter" :options="filterOptions" />
                </div>
                <div class="col-md-12 mt-3">
                  <label>Filter by Score</label>
                  <input type="text" class="form-control ion-height-helper" id="ion-start" data-fouc>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div
        class="card_mention"
        v-for="(mention, index) in mentions"
        :key="index"
      >
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="form-check pl-1 mb-0 mr-1">
                <label class="form-check-label">
                  <b-form-checkbox class="form-check-input-styled" />
                </label>
              </div>
              <div class="mr-3">
                <b-button class="btn-icon btn-sm bg_fb rounded-round">
                  <i class="icon-facebook"></i>
                </b-button>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
                <div class="rating_score">
                  <i class="icon-star-full2 point_yellow"></i>
                  <i class="icon-star-full2 point_yellow"></i>
                  <i class="icon-star-full2 point_yellow"></i>
                  <i class="icon-star-full2 point_gray"></i>
                  <i class="icon-star-full2 point_gray"></i>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
              <h4><a href="">facebbok.com</a></h4>
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
            <div class="row">
              <div class="col-md-8 mt-3">
                <b-form-select size="sm" :options="sentiments" />
              </div>
              <div class="col-md-4 mt_32">
                <div class="list-icons">
                  <b-dropdown
                    right
                    toggle-class="p-0 list-icons-item"
                    toggle-tag="a"
                    variant="transparent"
                  >
                    <template #button-content>
                      <i class="icon-list-unordered"></i>
                    </template>
                    <b-dropdown-item><i class="icon-sync"></i> Update data</b-dropdown-item>
                    <b-dropdown-item><i class="icon-list-unordered"></i> Detailed log</b-dropdown-item>
                    <b-dropdown-item><i class="icon-pie5"></i> Statistics</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item><i class="icon-cross3"></i> Clear lists</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-card>
        <ul class="pagination pagination-flat align-self-center">
          <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
          <li class="page-item active"><a href="#" class="page-link">1</a></li>
          <li class="page-item"><a href="#" class="page-link">2</a></li>
          <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
          <li class="page-item"><a href="#" class="page-link">4</a></li>
          <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
        </ul>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>

<script>
import chartDatas from '@/dummies/chartDatas.js'
import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    PieChart,
    LineChart,
  } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  } from "echarts/components";
  import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

  use([
    CanvasRenderer,
    PieChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  ])
export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return{
      dateRange: {},
      sentiments: ['Neutral', 'Positive', 'Negative'],
      mentions: [
        {
          type: 'fb',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Neutral',
        },
        {
          type: 'ig',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Positive',
        },
        {
          type: 'tw',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Negative',
        },
        {
          type: 'fb',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Neutral',
        },
        {
          type: 'ig',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Positive',
        },
        {
          type: 'tw',
          username: '@integradesign',
          status: 'Active',
          rating: 3,
          title: 'lamaran sebagai iOS Developer (3284527)',
          source: 'facebook.com',
          date: '2020-09-21 15:00',
          sentiment: 'Negative',
        },
      ],
      sentimentPieOptions: chartDatas.sentimentPieOptions,
      mentionSummaryOptions: chartDatas.mentionSummaryOptions,
      filterOptions: [
        { text: '-- Filter by --', value: '' },
        { text: 'Positive', value: 'pos' },
        { text: 'Negative', value: 'neg' },
        { text: 'Neutral', value: 'neu' },
      ],
      selFilter: '',
    }
  }
}
</script>